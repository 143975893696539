import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Fixed } from "@components/feature-list";

const GiftingSolutions = ({ reverse }) => {
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "iphone_cards" }) {
        sharp: childImageSharp {
          fixed(width: 438) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "iphone_cards" }) {
        sharp: childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      md: file(name: { eq: "iphone_cards" }) {
        sharp: childImageSharp {
          fixed(width: 560) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.xs.sharp.fixed,
    {
      ...images.sm.sharp.fixed,
      media: `(min-width: 640px AND max-width: 767px)`,
    },
    {
      ...images.md.sharp.fixed,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Fixed
      id="physical-eftpos"
      description={<Description />}
      image={sources}
      reverse={reverse} />
  );
}

const Description = () => (
  <div>
    <div>
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          Platform
        </span>

        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          Gifting Solutions
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        WAIVPAY’s suite of solutions includes our digital App that allows you to have a Branded App and Branded Digital card and a Webstore for online purchases of personalised physical Gift Cards; WAIVPAY has you covered.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        We support both Mastercard for Digital cards and EFTPOS for physical. Of course, all funds are held in trust to protect you, also you will be supported by our Customer Service team in Australia.
      </p>
    </div>
  </div>
);

export default GiftingSolutions;
